<!-- 房间管理  By阿旭-->
<template>
  <div class="main">
    <div class="top_btn">
      <div>
        <!--<el-select @change="queryChange" v-model="queryvalue" size="medium" placeholder="状态">-->
        <!--  <el-option-->
        <!--    v-for="item in options"-->
        <!--    :key="item.value"-->
        <!--    :label="item.label"-->
        <!--    :value="item.value"-->
        <!--  ></el-option>-->
        <!--</el-select>-->

<!--        v-if="roles.code === 'HOTEL_ADMIN'"-->
        <el-select
          clearable="true"
          v-btn-permission="'008003001002'"
          class="select-width"
          size="medium"
          @change="queryChange"
          v-model="isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>
      </div>

<!--      <div v-if="roles.code === 'HOTEL_EMPLOYEE'" v-btn-permission="'008003001001'">-->
      <div v-btn-permission="'008003001001'">
        <el-button type="primary" size="small" @click="addroom">新增房间</el-button>
      </div>
    </div>

    <!--<el-table :data="tableData" style="width: 100%">-->
    <!--  <el-table-column type="index" label="序号" width="55"></el-table-column>-->
    <!--  <el-table-column prop="floorNo" label="楼层"></el-table-column>-->
    <!--  <el-table-column prop="roomNo" label="房间号"></el-table-column>-->
    <!--  <el-table-column prop="windowFlag" label="是否有窗户">-->
    <!--    <template slot-scope="scope">-->
    <!--      <div style="white-space: nowrap" v-if="scope.row.windowFlag == 1">是</div>-->
    <!--      <div style="white-space: nowrap" v-if="scope.row.windowFlag == 0">否</div>-->
    <!--    </template>-->
    <!--  </el-table-column>-->
    <!--  <el-table-column prop="bedCount" label="床数"></el-table-column>-->
    <!--  <el-table-column prop="remark" label="其他信息"></el-table-column>-->
    <!--  <el-table-column prop="status" label="状态">-->
    <!--    <template slot-scope="scope">-->
    <!--      <el-tag size="small" type="danger" v-if="scope.row.status == 0">停用</el-tag>-->
    <!--      <el-tag size="small" v-else-if="scope.row.status == 1">启用</el-tag>-->
    <!--      <el-tag size="small" type="warning" v-else-if="scope.row.status == 2">空置</el-tag>-->
    <!--    </template>-->
    <!--  </el-table-column>-->

    <!--  <el-table-column label="操作" v-if="roles.code === 'HOTEL_EMPLOYEE'">-->
    <!--    <template slot-scope="scope">-->
    <!--      <el-link type="primary" style="margin-right:10px;" @click="arrange(scope.row)">编辑</el-link>-->
    <!--      <el-link-->
    <!--        v-if="scope.row.status == 1 || scope.row.status == 2"-->
    <!--        type="primary"-->
    <!--        style="color:#ff5050"-->
    <!--        @click="dictionaryFun(scope.row)"-->
    <!--      >停用</el-link>-->
    <!--      <el-link-->
    <!--        v-if="scope.row.status == 0 "-->
    <!--        type="primary"-->
    <!--        style="color:#12e07c"-->
    <!--        @click="dictionaryFun(scope.row)"-->
    <!--      >启用</el-link>-->
    <!--    </template>-->
    <!--  </el-table-column>-->
    <!--</el-table>-->

    <!--<div class="content_page">-->
    <!--  <el-pagination-->
    <!--    @size-change="handleSizeChange"-->
    <!--    @current-change="handleCurrentChange"-->
    <!--    :current-page="pageNumber"-->
    <!--    :total="total"-->
    <!--    :page-sizes="[20, 30, 50, 100]"-->
    <!--    :page-size="pageSize"-->
    <!--    layout="total, sizes, prev, pager, next, jumper"-->
    <!--  ></el-pagination>-->
    <!--</div>-->

    <!--统计-->
    <div class="census-box">
      <div class="census-item">
        <div class="census-title">总房数</div>
        <div class="census-num">{{ totalNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">可用房数量</div>
        <div class="census-num">{{ usableUseRoomNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">已用房数量</div>
        <div class="census-num">{{ useRoomNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">未用房数量</div>
        <div class="census-num">{{ unusedRoomNum }}</div>
      </div>
    </div>

    <!--楼层-->
    <div class="arrange_content_bottom">
      <div class="arrange_content_bottom_floor" v-for="(item, v) in roomListInfo" :key="v">
        <div style="padding: 6px 0;">{{ item.floorNo }}层</div>
        <div class="arrange_content_bottom_title">
          <div
            v-for="(list, d) in item.roomList"
            :key="d"
            @dblclick="arrange(list)"
            class="room-list"
            style="margin-right: 18px; margin-bottom: 12px;"
            :class="{
              'room-off': list.statusName === '停用',
              'room-normal': list.statusName === '已使用',
              'room-vacancy': list.statusName === '空置',
              'room-full': list.statusName === '满员',
              'room-dirty': list.statusName === '脏房'
            }">
            <div class="arrange_content_bottom_floor_num">{{ list.roomNo }}</div>
            <div class="arrange_content_bottom_floor_type">状态: {{ list.statusName }}</div>
            <div class="arrange-text">已入住: {{ list.livedCount }}</div>
            <div class="arrange-text" v-if="list.statusName === '脏房'">
              <span style="display: inline-block; line-height: 16px;">离开时间: {{ list.leaveTime }}</span>
            </div>
            <div class="arrange-text-name">{{ list.personNames }}</div>
            <div class="room-bubble" v-if="list.isShow && list.statusName === '脏房'">可启用</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增房间弹窗 -->
    <el-dialog v-if="isArrange" title="新增房间" :visible.sync="isArrange" width="40%">
      <el-form
        style="width:100%"
        :rules="rules"
        ref="redformRef"
        :model="redform"
        label-width="120px">
        <el-form-item class="formitem" label="楼层号：" prop="floorNo">
          <el-input style="width:90%" v-model="redform.floorNo"></el-input>
        </el-form-item>

        <el-form-item
          class="formitem"
          v-for="(domain, index) in redform.labDays"
          :label="'房间号' + (index+1) + '：'"
          :key="domain.key"
          :prop="'labDays.' + index + '.labDaysName'"
          :rules="{ required: true, message: '房间号不能为空', trigger: 'blur'}">
          <el-input style="width:66%" v-model="domain.labDaysName"></el-input>
          <el-button style="margin-left:10px" @click.prevent="removeDomain(index)">删除</el-button>
        </el-form-item>
        <el-form-item class="formitem" label="是否有窗：">
          <el-radio-group style="width:90%;" v-model="redform.windowFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="房间状态：" prop="status">
          <el-radio-group style="width:90%;" v-model="redform.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="床位数：" prop="bedCount">
          <el-input style="width:90%" v-model="redform.bedCount"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="备注：">
          <el-input style="width:90%" v-model="redform.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDomain">添加房间号</el-button>
        <el-button size="small" @click="isArrange = false">取消</el-button>
        <el-button size="small" type="primary" @click="fjbc">保存</el-button>
      </span>
    </el-dialog>

    <!-- 修改房间弹窗 -->
    <el-dialog v-if="isArrange02" title="修改房间" :visible.sync="isArrange02" width="40%">
      <el-form
        style="width:100%"
        :rules="rules"
        ref="redformRef2"
        :model="redFormInfo"
        label-width="120px">
        <el-form-item class="formitem" label="楼层号：" prop="floorNo">
          <el-input style="width:90%" v-model="redFormInfo.floorNo"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="房间号：">
          <el-input style="width:90%" v-model="redFormInfo.roomNo"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="是否有窗：">
          <el-radio-group style="width:90%;" v-model="redFormInfo.windowFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="房间状态：" prop="status">
          <el-radio-group style="width:90%;" v-model="redFormInfo.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">停用</el-radio>
            <el-radio label="2" v-if="redFormInfo.status == 2">脏房</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="离开时间：" v-if="redFormInfo.status == 2">
          <span style="display: inline-block; line-height: 16px;">{{ redFormInfo.leaveTime }}</span>
        </el-form-item>
        <el-form-item class="formitem" label="床位数：" prop="bedCount">
          <el-input style="width:90%" v-model="redFormInfo.bedCount"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="备注：">
          <el-input style="width:90%" v-model="redFormInfo.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isArrange02 = false">取消</el-button>
        <el-button type="primary" @click="fjbc02">修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  hotelroomntist,
  batchSave,
  isolationpointupdate,
  updateStatus,
  getRoomCountInfoApi,
  getIsolationInfoApi,
  hotelRoomInfoApi
} from "../../api/HotelManagement/roomManagement";
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      tableData: [],
      queryvalue: "",
      isolationPointId: "",
      isArrange: false, //新增房间弹出
      isArrange02: false,
      options: [
        { label: "全部", value:""},
        { label: "已使用", value: 1 },
        { label: "空置", value: 2 },
        { label: "停用", value: 0 }
      ],
      redform: {
        labDays: [{labDaysName: ""}],
        status: "1"
      },
      redFormInfo: {},
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      rules: {
        floorNo: [{ required: true, message: "楼层号不能为空", trigger: "blur" }],
        status: [{ required: true, message: "请选择房间状态", trigger: "change" }],
        bedCount: [{ required: true, message: "请输入床位数", trigger: "blur" }]
      },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      isActive: null,
      isolationHospitalId: "",
      isolationOrgCode: "",
      totalNum: "-",  // 总数
      usableUseRoomNum: "-",  // 可用
      useRoomNum: "-",  // 已用
      unusedRoomNum: "-",  // 未用
      roomListInfo: []
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList
    })
  },

  watch: {
    isolationList(val) {
      // 管理员权限走这个方法
      if (this.$btnPermission('008003001002')) {
        this.isolationPointId = val[0].id;
        this.isolationHospitalId = val[0].hospitalId;
        this.isolationOrgCode = val[0].orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      }
    }
  },

  mounted() {
    // this.list();
    // 如果当前登录 是非管理员
    // if (this.roles.code === 'HOTEL_EMPLOYEE') {
    // if (this.$store.getters.permission.btnLists.contain('008003001001', 'permission')) {
    if (this.$btnPermission('008003001001')) {
      this.isolationHospitalId = this.userdoctor.hospitalId;
      this.isolationOrgCode = this.userdoctor.orgCode;
      this.getRoomCountInfo();
      this.getIsolationInfo();
    } else if (this.$btnPermission('008003001002')){
      // 如果是管理员
      if (this.isolationList && this.isolationList.length > 0) {
        this.isolationPointId = this.isolationList[0].id;
        this.isolationHospitalId = this.isolationList[0].hospitalId;
        this.isolationOrgCode = this.isolationList[0].orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      }
    }
  },

  methods: {
    // 统计数据
    getRoomCountInfo() {
      let params = {
        isolationHospitalId: this.isolationHospitalId,
        isolationOrgCode: this.isolationOrgCode
      }
      getRoomCountInfoApi(params).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          // 总房间数 totalNum;
          // 停用房间数 stopNum;
          // 使用房间数 useRoomNum;

          // 未用 = 总 - 使用的
          // 可用 = 未用 - 停用
          this.totalNum = data.totalNum;
          this.useRoomNum = data.useRoomNum;
          this.unusedRoomNum = this.totalNum - this.useRoomNum;
          this.usableUseRoomNum = data.usableUseRoomNum;
        } else {
          this.$message.error(msg);
        }
      })
    },

    // 房间管理信息
    getIsolationInfo() {
      let params = {
        isolationPointId: this.isolationPointId,
        isolationHospitalId: this.isolationHospitalId,
        isolationOrgCode: this.isolationOrgCode
      };
      getIsolationInfoApi(params).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          data.forEach(item => {
            item.roomList.forEach(key => {
              if (key.status == 0) {
                key.statusName = '停用';
              } else if (key.status == 2) {
                key.statusName = '脏房';
              } else if (key.livedCount >= key.allCount) {
                key.statusName = "满员";
              } else if (key.livedCount > 0 && key.livedCount < key.allCount) {
                key.statusName = "已使用";
              } else if (key.livedCount == 0) {
                key.statusName = '空置';
              }

              // 计算离开房间的时间
              if (key.leaveTime) {
                let day3 = moment(key.leaveTime).add(3, 'days').format('YYYY-MM-DD HH:mm:ss')
                let greaterTimeTimestamp = new Date(day3).getTime()
                let currentTimestamp = new Date().getTime()

                // 当前时间 > 离开房间时间 加三天，就显示气泡
                key.isShow =  currentTimestamp > greaterTimeTimestamp ? true : false;
              }
            })
          })
          this.roomListInfo = data;
        } else {
          this.$message.error(msg);
        }
      })
    },

    // 点击新增房间
    addroom() {
      this.isArrange = true;
      this.redform = {
        labDays: [{ labDaysName: "" }],
        status: "1"
      };
    },

    removeDomain(index) {
      this.redform.labDays.splice(index, 1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.list();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.list();
    },
    addDomain() {
      this.redform.labDays.push({});
    },
    // 房间保存
    fjbc() {
      let form = this.redform;
      let a = JSON.parse(localStorage.getItem("userdoctor"));
      const nodes = form.labDays.map(item => ({
        roomNo: item.labDaysName
      }));
      for (let i in nodes) {
        nodes[i].bedCount = form.bedCount;
        nodes[i].remark = form.remark;
        nodes[i].windowFlag = form.windowFlag;
        nodes[i].floorNo = form.floorNo;
        nodes[i].hospitalId = a.hospitalId;
        nodes[i].orgCode = a.orgCode;
      }

      this.$refs.redformRef.validate( async valid => {
        if (valid) {
          let params = { hotelRooms: nodes };
          let result = await batchSave(params);
          let { code, data, msg } = result.data;
          if (code == 200) {
            this.isArrange = false;
            this.$message.success(msg)
            this.getIsolationInfo();
            this.getRoomCountInfo();
          }
        }
      });
    },
    // 房间修改
    async fjbc02() {
      this.$refs.redformRef2.validate( async valid => {
        if (valid) {
          let params = { ...this.redFormInfo };
          let { data } = await isolationpointupdate(params);
          if (data.code == 200) {
            this.$message.success("修改成功");
            this.isArrange02 = false;
            this.getIsolationInfo();
          }
        }
      });
    },
    // 查询列表
    async list() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      if (this.queryvalue) params.state = this.queryvalue;
      if (this.isolationPointId) params.isolationPointId = this.isolationPointId;

      let { data } = await hotelroomntist({ params });
      if (data.code == 200) {
        this.tableData = data.data.list;
        this.total = data.data.totalRow;
      } else {
        this.$message.error(data.msg);
      }
    },

    // 编辑
    arrange(row) {
      // console.log(11111111111,this.$store.getters.permission.btnLists);
      // 酒店职员才可以编辑
      if (this.$store.getters.permission.btnLists.contain('008003001003', 'permission')) {
        this.isArrange02 = true;
        let params = {
          id: row.hotelRoomId
        };
        hotelRoomInfoApi({ params }).then(res => {
          let { code, data, msg } = res.data;
          if (code == 200) {
            this.redFormInfo = data;
          } else {
            this.$message.error(msg)
          }
        })
      }else{
        console.log('没有权限');
      }
    },
    // 停用
    async dictionaryFun(row) {
      let status = 0;
      if (row.status == 1 || row.status == 2) {
        status = 0;
      } else {
        status = 2;
      }
      let params = {
        id: row.id,
        status: status
      };
      let { data } = await updateStatus(params);
      if (data.code == 200) {
        this.list();
        this.$forceUpdate();
      } else {
        this.$message.error(data.msg)
      }
    },
    // 隔离点查询
    queryChange() {
      this.isolationList.forEach(item => {
        if (item.id == this.isolationPointId) {
          this.isolationHospitalId = item.hospitalId;
          this.isolationOrgCode = item.orgCode;
        }
      })

      this.getRoomCountInfo();
      this.getIsolationInfo();
    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/room-list.scss";

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top_btn {
  display: flex;
  justify-content: space-between;
}
.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.content {
  flex: 1;
  // overflow-x: hidden;
  // overflow-y: scroll;
}

.arrange_content_bottom{
  margin-top: 10px;
  overflow: visible;
  height: 100%;
}

//统计数据
.census-box{
  display: flex;
  margin-top: 20px;

  .census-item{
    width: 25%;
    border-radius: 10px;
    margin-right: 30px;
    padding: 12px 18px;
    box-sizing: border-box;
    color: #ffffff;

    &:nth-child(1) {
      background-image: linear-gradient(140deg, #4cd9ff, #00c9ff);
    }

    &:nth-child(2) {
      background: linear-gradient(140deg, #3fefd0, #18d3b2);
    }

    &:nth-child(3) {
      background: linear-gradient(140deg, #e097ff, #bd4ced);
    }

    &:nth-child(4) {
      margin-right: 0;
      background: linear-gradient(140deg, #F9B382, #eb8843);
    }

    .census-title{
      font-size: 15px;
    }

    .census-num{
      font-size: 40px;
      font-weight: bold;
    }
  }
}
</style>
